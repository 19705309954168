html {
  text-size-adjust: 100%;
}

body {
  font: 16px/1 sans-serif;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
p,
blockquote,
figure,
ol,
ul {
  margin: 0;
  padding: 0;
}

main,
li {
  display: block;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
}

strong {
  font-weight: bold;
}

a,
button {
  color: inherit;
  transition: 0.3s;
}

a {
  text-decoration: none;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  overflow: visible;
  -webkit-font-smoothing: inherit;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
}

* {
  box-sizing: border-box;
}
