/* Media query breakpoints */
$width-sm: 600px;
$width-md: 960px;
$width-lg: 1280px;
$width-xl: 1920px;
$sidebar-right-width: 400px;
$height-header: 100px;
$red: #dd3e3e;
$green: #81ba47;
$green-hover: #5da614;
$blue: #6093cc;
$link-blue: #6093cc;
$blue-hover: #87add8;
$link-blue-hover: #4d76a3;
$input-border-focus: #030303;
$light-grey: #f5f5f5;
$input-border-focus: #030303;
$grey: #909090;
$grey-hover: #8a9093;
