$scrollbar-background-color: #818a91;

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: $scrollbar-background-color;
}

::-webkit-scrollbar-thumb {
  background-color: darken($scrollbar-background-color, 30%);

  &:hover {
    background-color: darken($scrollbar-background-color, 20%);
  }
}

::-webkit-resizer,
::-webkit-scrollbar-button,
::-webkit-scrollbar-corner {
  display: none;
}
