@import 'variables';
@import 'mixins';

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.btn-standart {
  background: $green;
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  text-transform: capitalize;
  min-width: 100px;

  @include btn-modifiers {
    background: $green-hover;
  }

  &:disabled {
    cursor: default !important;
    opacity: 0.65;
  }


  &.btn-blue {
    background: $blue;

    @include btn-modifiers {
      background: $blue-hover;
    }

  }

  &.btn-green {
    background-color: $green;

    @include btn-modifiers {
      background: $green-hover;
    }
  }

  &.btn-grey {
    background-color: $grey;

    @include btn-modifiers {
      background: $grey-hover;
    }
  }
}

.btn-link {
  display: inline;
  padding: 0;
  margin: 0;
  color: $blue;
  cursor: pointer;
  border: 0;
  font-size: 1em;

  @include btn-modifiers {
    color: $blue-hover;
    text-decoration: underline;
    outline: none;
  }
}

