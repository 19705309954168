/* mixins */
/* stylelint-disable */
@mixin placeholder-color($color: #000) {
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: $color;
  }

  :-ms-input-placeholder { /* IE 10+ */
    color: $color;
  }

  :-moz-placeholder { /* Firefox 18- */
    color: $color;
  }
}

@mixin btn-modifiers {
  &.active,
  &:hover,
  &.focus,
  &:focus {
    @content;
  }
}
