.google-plus-icon {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAXCAYAAAAP6L+eAAACF0lEQVQ4jZWVT0hVQRTGf+9hJiqYkFCokbSprESLNhWBm1YtKggiF4Grtq2jXbRz4076RxG2aNOiRUFEFEQtIxSCIpNK+idBSmX5a3FPOj7ve/d1YLgzc7755sw359wpqeRYJ3AI6AfagKYK/3fgLPA+bzFAQ8V4HTAC9AG3gUXgcMwD3AReAWO1SAFQ/7UO9YX6RG1M5reoH83sdeAoaulgPBYfzQEOuWxj/0Pcpf6JhQM5wAZ1Jvyz9RCXQ5E9sNTvzFHsN/AguYc1NfVNyErJ3EAV7FR8PwML9RI/jagAhlidLQAt8b2W42sHulfMJLqMJhd0rkKzNvVtZExzjqYX1KlqWdGoXlQXg/yqekQdVifVO2pLlctaRVxydeXtIKu6DuA40AN8AjYD8wluI9Ab/WFgEDgZ429FaTMd0c/kSHDa6va8iPheAn6obkh869Xd0a6oH5LxtmqE3eqger4ikml1Zz0alxPNNgGjwBvgLnAKmAPOABOB6QLuh+61LXY4oc6pL9UDORE1qzeSyMcr/O1xyhXp1qf+Un+qPTX0LqmXgni+4G5AvR7gZ0XgiGwhAqmJbQC2hiprC3WDWeBHonlVKwOT0d8F7CvA9wOtZK9MbVN74+JUv6jH1HLO8barE+pIHZItlfRe4DLLJfoOeExWyk1kcnWQPaC3CqOFFf+KMnAQ2E+W063A19jkUWyU+6Tn2V9O+pK3VsihOwAAAABJRU5ErkJggg==');
  height: 23px;
  margin-top: 2px;
  width: 22px;
}

.upwork-icon {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAUCAYAAACeXl35AAACdElEQVRIiaWVS0hVURSGP283tcyeFlGJg5LsRYMGGTRo4CQIAssgosAIokE0CBo2ahQRNTIaWdAgqklQgyZBoQRFNZBMwpCsMIReZpmvr8Het3u8nctV++Fwzjrr8e+99lpro75RJ9UBtVml4KlTH6njBpxVsyl203oywBKgDKgGKvkXc4CF8Q2wC6hKsQPIAGuATcD6GHsKssA3YBkwBoymBJkAfibkRmAD8KTAbjvQAmwFVsRYvcBD4BbwGQD1bUzVV3V/kZR2OhWXCmyOqK9Nx7h6U61XZ004qK6N+qYol8J9tSZT5CxKoQY4AVQAZ6IMMAxcBpqB40BHwmc30JqdJSHAPsL5Nkb5N3ABOE/+zB8AV4EmQmHuzcSPHCxBMgYMRqI64BShugGeAW1MLbA+oJ1QmAC1hSlNI5xM/M/GAN1xoRUJv8fApxT/PkKqASoywFAUyuOqC7EaWB6/y4CnwEXCLnP4DvSk+ALUk8/CSAboj8I8Qh9tjDvJAAuAQ8C6aPMFeA9cB+4mglYCO+KC50bfLKH5WwmDI+xWbTU/tlSfq6fVo+oddSyhu6FWx3Y4llL6Peo59XCM8aKgH0+izlfb1YkSfdSjbo5kZeqVafReDhO5xeaae7HaZnoD/1I71J3mh0GV+irqR9QutbcI2WCMvVSlTKcUZguwB1gZ5WGgE7hGaIccGoCXhCodJDT5O+Bg1JXHohoA7gG3/3qafo1Uq4uK6FAPmD+CfrUhoSuPGatK8y02aYaK/M9hW6xEgI/Ah4RulPRbBxJOM8WW+J4EuoAf03WcDeEqwp0H4Zx6KD0S/4uwNhJMEiZM90yc/wAcOAZee7RtYAAAAABJRU5ErkJggg==');
  height: 20px;
  margin-top: 4px;
  width: 28px;
}

.password-eye-icon {
  background: url('/app/assets/icon/password-eye.png');
  height: 21px;
  width: 28px;
}

.password-eye-hide-icon {
  background: url('/app/assets/icon/password-eye-hide.png');
  height: 22px;
  margin-top: -1px;
  width: 28px;
}

.banner-close-icon {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAc0lEQVQokZXSQQrAIAxE0dC/Nwes1+257MZAiXHaCoJGH2qMAQZ0oM2x6g04AQs0gOsFt7lnAD0Hdjjv8VhwgRcUV1W4RBlWuEQVzLhEgB22tlHMc2w5Mb9pm22ZcpVtmXL1VW9oh/1PyT1xj+DXIndmkd/7MUGSfucTDgAAAABJRU5ErkJggg==');
  height: 14px;
  width: 14px;
}

.notification-icon {
  display: block;
  height: 42px;
  width: 42px;
}

.google-icon {
  background: url('/app/assets/icon/google.svg');
  height: 34px;
  width: 34px;
}

.checked-icon {
  background: url('/app/assets/icon/checked.png');
  height: 34px;
  width: 34px;
}

.tos-checked {
  width: 20px;
  height: 20px;
  background: url('/app/assets/icon/checkbox_checked.svg');
}

.tos-checked_error {
  width: 20px;
  height: 20px;
  background: url('/app/assets/icon/checkbox_not_checked_error.svg');
}

.tos-not_checked {
  width: 20px;
  height: 20px;
  background: url('/app/assets/icon/checkbox_not_checked.svg');
}

.company_underline {
  background: url('/app/assets/icon/company_underline.svg');
}

.ellipse {
  // width: 20px;
  // height: 20px;
  background: url('/app/assets/icon/ellipse.svg');
}

