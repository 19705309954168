/*
 * This file is for setting global styles
 */
@import './styles/normalize.css';
@import './styles/shared/variables';
@import './styles/webkit-scrollbar';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import './styles/shared/icons';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
@import './styles/buttons';

html {
  font-size: 14px;
  height: 100%;
}

body {
  min-height: 100vh;
  font-family: 'Open Sans', serif;
}

h1,
h2,
h3,
h4 {
  cursor: default;
}

//// Disables mouse selection.
//* {
//  user-select: none;
//}

.red {
  color: $red;
}

input[type=password]::-ms-reveal {
  display: none;
}

::-ms-clear {
  display: none;
}

::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

/* Notifications */

.notification-main {
  color: #424242 !important;
}

.simple-notification {
  padding: 4px 20px;
  min-height: 70px;
  margin-bottom: 22px !important;

  .icon {
    top: 50% !important;
    height: initial !important;
    transform: translateY(-50%);
    color: #424242 !important;

    svg {
      fill: #424242 !important;

      g {
        fill: inherit !important;
      }

      path {
        fill: inherit !important;
      }
    }
  }

  .sn-title {
    font-weight: 700;
    font-size: 18px !important;
  }

  .sn-content {
    font-size: 14px !important;
    word-break: break-word;
    padding-right: 50px;
  }
}

.simple-notification.success {
  background-color: #a3d869 !important;
}

.simple-notification.alert {
  border-radius: 0 !important;
  background-color: #f3c85b !important;
}

.simple-notification.error {
  background-color: #f98673 !important;
}

/* END Notifications */

// Spinner
/* stylelint-disable */
.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #6093cc;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/* stylelint-enable */

.validated .form-control:invalid,
.validated .ng-invalid.ng-dirty {
  border-color: #dc3545 !important;
}

.form-control.invalid {
  border-color: #dc3545 !important;
}

input:not(.ng-invalid),
textarea:not(.ng-invalid) {
  &:focus {
    border-color: $input-border-focus;
  }
}

.form-control {
  &:focus {
    border-color: $input-border-focus;
  }
}

input,
textarea {
  &:focus {
    box-shadow: none !important;
  }
}
